import { Fragment, useState } from "react";
import { Dialog, Menu, Transition } from "@headlessui/react";
import {
  Bars3BottomLeftIcon,
  BellIcon,
  CalendarIcon,
  ChartBarIcon,
  FolderIcon,
  HomeIcon,
  InboxIcon,
  UsersIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import { Link, Outlet, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useAuth } from "../hooks/useAuth2";
import { TabItem, useAuthenticator } from "@aws-amplify/ui-react";
import {
  AppShell,
  Navbar,
  Header,
  Title,
  NavLink,
  Button,
} from "@mantine/core";
import { IconHome2, IconUsers } from "@tabler/icons";

// const navigation = [
//   { name: "Dashboard", href: "/", icon: HomeIcon },
//   { name: "Patients", href: "/patients", icon: UsersIcon },
//   // { name: "Projects", href: "#", icon: FolderIcon, current: false },
//   // { name: "Calendar", href: "#", icon: CalendarIcon, current: false },
//   // { name: "Documents", href: "#", icon: InboxIcon, current: false },
//   // { name: "Reports", href: "#", icon: ChartBarIcon, current: false },
// ];
const userNavigation = [
  { name: "Your Profile", href: "#" },
  { name: "Settings", href: "#" },
  { name: "Sign out", href: "#" },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const navigation = [
  { name: "Dashboard", href: "/", icon: IconHome2 },
  { name: "Patients", href: "/patients", icon: IconUsers },
];

const Root = () => {
  const location = useLocation();
  const { signOut } = useAuthenticator((context) => [context.signOut]);

  return (
    <AppShell
      padding={0}
      navbar={
        <Navbar
          width={{ base: 300 }}
          height="full"
          p="xs"
          className="border border-gray-500"
        >
          <div className="flex flex-col justify-between h-full">
            <div className="flex flex-col justify-start">
              {navigation.map((item) => (
                <NavLink
                  key={item.name}
                  label={item.name}
                  component={Link}
                  to={item.href}
                  active={location.pathname === item.href}
                  icon={<item.icon size={16} stroke={1.5} />}
                />
              ))}
            </div>
            <Button onClick={() => signOut()}>Sign out</Button>
          </div>
        </Navbar>
      }
      header={
        <Header height={60} pl="lg" className="border border-gray-500 ">
          <div className="flex flex-col justify-center h-full">
            <Title order={3}>Innovia Mental Health</Title>
          </div>
        </Header>
      }
      styles={(theme) => ({
        main: {
          backgroundColor:
            theme.colorScheme === "dark"
              ? theme.colors.dark[8]
              : theme.colors.gray[0],
        },
      })}
    >
      <Outlet />
    </AppShell>
  );
};

export default Root;

// export function loader() {
//   return fetch(
//     `https://script.google.com/macros/s/AKfycbxZdDoENsZFCsDZBtXpaY0xR5wZyxQE-iBJe29LUlEwnb-6qTCxzmFQbx4vxTWlaAoSVA/exec?route=patients`
//   );
// }

// export default function Root() {
//   const [sidebarOpen, setSidebarOpen] = useState(false);
//   // const { patients } = useLoaderData();
//   const patients = fakePatientData;
//   // const auth = useAuth();
//   const { signOut } = useAuthenticator((context) => [context.signOut]);

//   console.log(patients);

//   return (
//     <>
//       {/*
//         This example requires updating your template:

//         ```
//         <html class="h-full bg-gray-100">
//         <body class="h-full">
//         ```
//       */}
//       <Helmet>
//         <html className="h-full bg-gray-100" />
//         <body className="h-full" />
//       </Helmet>
//       <div>
//         {/* Sidebar for mobile */}
//         <Transition.Root show={sidebarOpen} as={Fragment}>
//           <Dialog
//             as="div"
//             className="relative z-40 md:hidden"
//             onClose={setSidebarOpen}
//           >
//             <Transition.Child
//               as={Fragment}
//               enter="transition-opacity ease-linear duration-300"
//               enterFrom="opacity-0"
//               enterTo="opacity-100"
//               leave="transition-opacity ease-linear duration-300"
//               leaveFrom="opacity-100"
//               leaveTo="opacity-0"
//             >
//               <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
//             </Transition.Child>

//             <div className="fixed inset-0 z-40 flex">
//               <Transition.Child
//                 as={Fragment}
//                 enter="transition ease-in-out duration-300 transform"
//                 enterFrom="-translate-x-full"
//                 enterTo="translate-x-0"
//                 leave="transition ease-in-out duration-300 transform"
//                 leaveFrom="translate-x-0"
//                 leaveTo="-translate-x-full"
//               >
//                 <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-white pt-5 pb-4">
//                   <Transition.Child
//                     as={Fragment}
//                     enter="ease-in-out duration-300"
//                     enterFrom="opacity-0"
//                     enterTo="opacity-100"
//                     leave="ease-in-out duration-300"
//                     leaveFrom="opacity-100"
//                     leaveTo="opacity-0"
//                   >
//                     <div className="absolute top-0 right-0 -mr-12 pt-2">
//                       <button
//                         type="button"
//                         className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
//                         onClick={() => setSidebarOpen(false)}
//                       >
//                         <span className="sr-only">Close sidebar</span>
//                         <XMarkIcon
//                           className="h-6 w-6 text-white"
//                           aria-hidden="true"
//                         />
//                       </button>
//                     </div>
//                   </Transition.Child>
//                   <div className="flex flex-shrink-0 items-center px-4">
//                     {/* <img
//                       className="h-8 w-auto"
//                       src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
//                       alt="Your Company"
//                     /> */}
//                     <span className="font-black text-xl tracking-wide">
//                       Innovia
//                     </span>
//                   </div>
//                   <div className="mt-5 h-0 flex-1 overflow-y-auto">
//                     <nav className="space-y-1 px-2">
//                       {navigation.map((item) => (
//                         <NavLink
//                           key={item.name}
//                           to={item.href}
//                           className={({ isActive }) =>
//                             classNames(
//                               isActive
//                                 ? "bg-gray-100 text-gray-900"
//                                 : "text-gray-600 hover:bg-gray-50 hover:text-gray-900",
//                               "group flex items-center px-2 py-2 text-base font-medium rounded-md"
//                             )
//                           }
//                           end={item.href === "/" ? true : null}
//                         >
//                           {({ isActive }) => (
//                             <>
//                               <item.icon
//                                 className={classNames(
//                                   isActive
//                                     ? "text-gray-500"
//                                     : "text-gray-400 group-hover:text-gray-500",
//                                   "mr-4 flex-shrink-0 h-6 w-6"
//                                 )}
//                                 aria-hidden="true"
//                               />
//                               {item.name}
//                             </>
//                           )}
//                         </NavLink>
//                       ))}
//                     </nav>
//                   </div>
//                 </Dialog.Panel>
//               </Transition.Child>
//               <div className="w-14 flex-shrink-0" aria-hidden="true">
//                 {/* Dummy element to force sidebar to shrink to fit close icon */}
//               </div>
//             </div>
//           </Dialog>
//         </Transition.Root>

//         {/* Static sidebar for desktop */}
//         <div className="hidden md:fixed md:inset-y-0 md:flex md:w-64 md:flex-col">
//           {/* Sidebar component, swap this element with another sidebar if you like */}
//           <div className="flex flex-grow flex-col overflow-y-auto border-r border-gray-200 bg-white pt-5">
//             <div className="flex flex-shrink-0 items-center px-4">
//               {/* <img
//                 className="h-8 w-auto"
//                 src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
//                 alt="Your Company"
//               /> */}
//               <span className="font-black text-xl tracking-wide">Innovia</span>
//             </div>
//             <div className="mt-5 flex flex-grow flex-col">
//               <nav className="flex-1 space-y-1 px-2 pb-4">
//                 {navigation.map((item) => (
//                   <NavLink
//                     key={item.name}
//                     to={item.href}
//                     className={({ isActive }) =>
//                       classNames(
//                         isActive
//                           ? "bg-gray-100 text-gray-900"
//                           : "text-gray-600 hover:bg-gray-50 hover:text-gray-900",
//                         "group flex items-center px-2 py-2 text-sm font-medium rounded-md"
//                       )
//                     }
//                     end={item.href === "/" ? true : null}
//                   >
//                     {({ isActive }) => (
//                       <>
//                         <item.icon
//                           className={classNames(
//                             isActive
//                               ? "text-gray-500"
//                               : "text-gray-400 group-hover:text-gray-500",
//                             "mr-3 flex-shrink-0 h-6 w-6"
//                           )}
//                           aria-hidden="true"
//                         />
//                         {item.name}
//                       </>
//                     )}
//                   </NavLink>
//                 ))}
//               </nav>
//             </div>
//           </div>
//         </div>
//         <div className="flex flex-1 flex-col md:pl-64">
//           <div className="sticky top-0 z-10 flex h-16 flex-shrink-0 bg-white shadow">
//             <button
//               type="button"
//               className="border-r border-gray-200 px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden"
//               onClick={() => setSidebarOpen(true)}
//             >
//               <span className="sr-only">Open sidebar</span>
//               <Bars3BottomLeftIcon className="h-6 w-6" aria-hidden="true" />
//             </button>
//             <div className="flex flex-1 justify-between px-4">
//               <div className="flex flex-1">
//                 <form className="flex w-full md:ml-0" action="#" method="GET">
//                   <label htmlFor="search-field" className="sr-only">
//                     Search
//                   </label>
//                   <div className="relative w-full text-gray-400 focus-within:text-gray-600">
//                     <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center">
//                       <MagnifyingGlassIcon
//                         className="h-5 w-5"
//                         aria-hidden="true"
//                       />
//                     </div>
//                     <input
//                       id="search-field"
//                       className="block h-full w-full border-transparent py-2 pl-8 pr-3 text-gray-900 placeholder-gray-500 focus:border-transparent focus:placeholder-gray-400 focus:outline-none focus:ring-0 sm:text-sm"
//                       placeholder="Search"
//                       type="search"
//                       name="search"
//                       autocomplete="off"
//                     />
//                   </div>
//                 </form>
//               </div>
//               <div className="ml-4 flex items-center md:ml-6">
//                 <button
//                   type="button"
//                   className="rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
//                 >
//                   <span className="sr-only">View notifications</span>
//                   <BellIcon className="h-6 w-6" aria-hidden="true" />
//                 </button>

//                 {/* Profile dropdown */}
//                 <Menu as="div" className="relative ml-3">
//                   <div>
//                     <Menu.Button className="flex max-w-xs items-center rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
//                       <span className="sr-only">Open user menu</span>
//                       <img
//                         className="h-8 w-8 rounded-full"
//                         src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
//                         alt=""
//                       />
//                     </Menu.Button>
//                   </div>
//                   <Transition
//                     as={Fragment}
//                     enter="transition ease-out duration-100"
//                     enterFrom="transform opacity-0 scale-95"
//                     enterTo="transform opacity-100 scale-100"
//                     leave="transition ease-in duration-75"
//                     leaveFrom="transform opacity-100 scale-100"
//                     leaveTo="transform opacity-0 scale-95"
//                   >
//                     <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
//                       {/* {userNavigation.map((item) => (
//                         <Menu.Item key={item.name}>
//                           {({ active }) => (
//                             <a
//                               href={item.href}
//                               className={classNames(
//                                 active ? "bg-gray-100" : "",
//                                 "block px-4 py-2 text-sm text-gray-700"
//                               )}
//                             >
//                               {item.name}
//                             </a>
//                           )}
//                         </Menu.Item>
//                       ))} */}
//                       <Menu.Item>
//                         {({ active }) => (
//                           <button
//                             className={classNames(
//                               active ? "bg-gray-100" : "",
//                               "block px-4 py-2 text-sm text-gray-700"
//                             )}
//                             onClick={() => signOut()}
//                           >
//                             Sign out
//                           </button>
//                         )}
//                       </Menu.Item>
//                     </Menu.Items>
//                   </Transition>
//                 </Menu>
//               </div>
//             </div>
//           </div>

//           <Outlet context={{ patients }} />
//         </div>
//       </div>
//     </>
//   );
// }
