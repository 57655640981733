import { useState } from "react";
import { Title, TextInput, Button, SegmentedControl } from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import { Auth } from "aws-amplify";
import { useNavigate, Form } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { post } from "../utils/api";

// export const action = (queryClient) =>
// async ({ request, params }) => {
//   const formData = await request.formData();
//   const patient
// }

const AddPatient = () => {
  const [dateOfBirth, setDateOfBirth] = useState(null);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [sex, setSex] = useState("");
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { mutate: addPatient } = useMutation(
    async () => {
      const data = {
        first_name: firstName,
        last_name: lastName,
        date_of_birth: dateOfBirth,
        sex: sex,
      };
      return await post("patients", data);
    },
    {
      onSuccess: async (data, variables, context) => {
        await queryClient.invalidateQueries(["patients", "list"]);
        const patient_id = data["patient_id"];
        navigate(`/patient/${patient_id}`, { replace: true });
      },
    }
  );

  const savePatient = () => {
    Auth.currentSession().then((res) => {
      const jwt = res.getIdToken().getJwtToken();
      const data = {
        first_name: firstName,
        last_name: lastName,
        date_of_birth: dateOfBirth,
        sex: sex,
      };
      fetch(`https://7dqnwcg73m.execute-api.us-west-2.amazonaws.com/patients`, {
        headers: {
          Authorization: jwt,
          accept: "application/json",
          "Content-Type": "application/json",
        },
        credentials: "include",
        mode: "cors",
        method: "POST",
        body: JSON.stringify(data),
      })
        .then((response) => response.json())
        .then((data) => {
          const patient_id = data["patient_id"];
          navigate(`/patient/${patient_id}`, { replace: true });
        });
    });
  };

  return (
    <div className="flex flex-col h-full">
      {/* <Form method="post"> */}
      <Title order={4} mb="lg">
        Add New Patient
      </Title>
      <TextInput
        mb="md"
        placeholder="Enter first name here"
        label="First Name"
        value={firstName}
        onChange={(e) => setFirstName(e.currentTarget.value)}
      />
      <TextInput
        mb="md"
        placeholder="Enter last name here"
        label="Last Name"
        value={lastName}
        onChange={(e) => setLastName(e.currentTarget.value)}
      />
      <DatePicker
        mb="md"
        placeholder="mm/dd/yyyy"
        label="Date of Birth"
        value={dateOfBirth}
        onChange={setDateOfBirth}
        allowFreeInput
      />
      <SegmentedControl
        className="self-start"
        data={[
          { label: "Male", value: "M" },
          { label: "Female", value: "F" },
        ]}
        value={sex}
        onChange={setSex}
      />
      <div className="flex flex-row justify-end">
        {/* <Button type="submit">Submit</Button> */}
        <Button onClick={addPatient}>Submit</Button>
      </div>
      {/* </Form> */}
    </div>
  );
};

export default AddPatient;
