import { Navigate, useLocation, useSearchParams } from "react-router-dom";
import { useAuth } from "../hooks/useAuth2";
import { useAuthenticator } from "@aws-amplify/ui-react";

// const AuthenticatedRoute = ({ element }) => {
//   const auth = useAuth();
//   const location = useLocation();

//   if (auth.user != null) {
//     return element;
//   }

//   return (
//     <Navigate
//       to={`/signin?next=${encodeURIComponent(location.pathname)}`}
//       replace
//     />
//   );
// };

const AuthenticatedRoute = ({ element }) => {
  const location = useLocation();
  const { route } = useAuthenticator((context) => [context.route]);

  if (route !== "authenticated") {
    return <Navigate to="/signin" state={{ from: location }} replace />;
  }
  return element;
};

// const UnauthenticatedOnlyRoute = ({ element }) => {
//   const auth = useAuth();
//   const queryParams = useSearchParams();

//   if (auth.user != null) {
//     return (
//       <Navigate
//         to={queryParams?.next ? decodeURIComponent(queryParams.next) : "/"}
//         replace
//       />
//     );
//   }

//   return element;
// };

export { AuthenticatedRoute };
