import { useParams } from "react-router-dom";
import { Title, Anchor } from "@mantine/core";
import { InView } from "react-intersection-observer";
import { useEffect, useState } from "react";
import {
  SingleSelectQuestion,
  MultipleSelectQuestion,
  DefaultQuestion,
  YesNoQuestion,
} from "../components/question-types";
import { initialData_v1 as initialData } from "../data/intake-data-format";
import { noteGen_v1 as data } from "../data/note-gen-ui";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const questionTypesMap = {
  single_select: SingleSelectQuestion,
  multiple_select: MultipleSelectQuestion,
  yes_no: YesNoQuestion,
};

const Patient = () => {
  const { patientId } = useParams();

  const [anchorIndex, setAnchorIndex] = useState(0);
  const [symptoms, setSymptoms] = useState(initialData);

  const handleViewStateChanged = (id, inView) => {
    const index = data.findIndex((item) => item.id === id);
    if (index === anchorIndex && !inView) {
      setAnchorIndex(anchorIndex + 1);
    } else if (index === anchorIndex - 1 && inView) {
      setAnchorIndex(anchorIndex - 1);
    }
  };

  const setCurrentAnchorIndex = (id) => {
    const index = data.findIndex((item) => item.id === id);
    setAnchorIndex(index);
  };

  return (
    <>
      <div className="h-full flex flex-row items-stretch">
        <div className="flex flex-col grow px-4">
          {data.map(({ type, text, id, questions }) => (
            <InView
              key={id}
              rootMargin="-60px 0px 0px 0px"
              as="div"
              onChange={(inView, entry) => handleViewStateChanged(id, inView)}
            >
              {type === "section_header" && (
                <Title id={id} order={2} className="scroll-mt-16 mt-4">
                  {text}
                </Title>
              )}
              {type === "disorder" && (
                <>
                  <Title id={id} order={3} className="mb-2 scroll-mt-16 mt-4">
                    {text}
                  </Title>
                  {questions.map((params) => {
                    const Question =
                      questionTypesMap[params.type] ?? DefaultQuestion;
                    return (
                      <Question
                        key={params.id}
                        params={params}
                        symptoms={symptoms}
                        setSymptoms={setSymptoms}
                      />
                    );
                  })}
                </>
              )}
            </InView>
          ))}
        </div>
        <div className="shrink-0 grow-0 basis-[300px]">
          <div className="fixed flex flex-col p-4 space-y-1">
            {data.map(({ id, text, type }) => (
              <Anchor
                href={`#${id}`}
                key={id}
                className={classNames(
                  data[anchorIndex].id === id
                    ? "text-black border-solid border-l-2 border-blue-400"
                    : "text-gray-400",
                  type === "section_header" ? "pl-4" : "pl-8",
                  "leading-snug font-semibold"
                )}
                onClick={() => setCurrentAnchorIndex(id)}
              >
                {text}
              </Anchor>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Patient;
