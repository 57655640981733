import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import ErrorPage from "./error-page";
import Dashboard from "./routes/dashboard";
import Patient from "./routes/patient";
import PatientForms from "./routes/patient-forms";
import PatientOverview from "./routes/patient-overview";
import PatientHistory from "./routes/patient-history";
import Patients, { loader as patientsLoader } from "./routes/patients";
import Root, { loader as rootLoader } from "./routes/root";
import SignIn from "./routes/signin";
import { AuthenticatedRoute } from "./utils/protectedroutes";
import { Amplify } from "aws-amplify";
import { Authenticator } from "@aws-amplify/ui-react";
import AddPatient from "./routes/add-patient";
import { MantineProvider } from "@mantine/core";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

const queryClient = new QueryClient();

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route
        path="/"
        element={<AuthenticatedRoute element={<Root />} />}
        errorElement={<ErrorPage />}
      >
        <Route index element={<Dashboard />} />
        <Route path="patients" element={<Patients />} loader={patientsLoader} />
        <Route path="add-patient" element={<AddPatient />} />
        <Route path="patient/:patientId" element={<Patient />}>
          <Route index element={<PatientOverview />} />
          <Route path="forms" element={<PatientForms />} />
          <Route path="history" element={<PatientHistory />} />
        </Route>
      </Route>
      <Route path="/signin" element={<SignIn />} />
      <Route path="*" element={<ErrorPage />} />
    </>
  )
);

Amplify.configure({
  Auth: {
    region: process.env.REACT_APP_AWS_COGNITO_REGION,
    userPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
    userPoolWebClientId:
      process.env.REACT_APP_AWS_COGNITO_USER_POOL_WEB_CLIENT_ID,
    oauth: {
      domain: process.env.REACT_APP_AWS_COGNITO_DOMAIN,
      scope: ["email", "openid", "phone"],
    },
    redirectSignIn: process.env.REACT_APP_REDIRECT_SIGN_IN,
    redirectSignOut: process.env.REACT_APP_REDIRECT_SIGN_OUT,
    responseType: "code",
  },
});

function App() {
  return (
    <>
      <Authenticator.Provider>
        <MantineProvider
          withGlobalStyles
          withNormalizeCSS
          // theme={{ colorScheme: "dark" }}
        >
          <QueryClientProvider client={queryClient}>
            <RouterProvider router={router} />
            <ReactQueryDevtools
              initialIsOpen={false}
              position={"bottom-right"}
            />
          </QueryClientProvider>
        </MantineProvider>
      </Authenticator.Provider>
    </>
  );
}

export default App;
