import { Auth } from "aws-amplify";

const getToken = async () => {
  const session = await Auth.currentSession();
  return session.getIdToken().getJwtToken();
};

export async function get(url) {
  const token = await getToken();
  const response = await fetch(`${process.env.REACT_APP_BASE_API_URL}/${url}`, {
    headers: { Authorization: token, accept: "application/json" },
    credentials: "include",
    mode: "cors",
    method: "GET",
  });

  if (!response.ok) {
    throw new Error();
  }
  return response.json();
}

export async function post(url, body) {
  const token = await getToken();
  const response = await fetch(`${process.env.REACT_APP_BASE_API_URL}/${url}`, {
    headers: {
      Authorization: token,
      accept: "application/json",
      "Content-Type": "application/json",
    },
    credentials: "include",
    mode: "cors",
    method: "POST",
    body: JSON.stringify(body),
  });

  if (!response.ok) {
    throw new Error();
  }
  return response.json();
}
