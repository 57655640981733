import { Auth } from "aws-amplify";
import { Link } from "react-router-dom";
import { Table, Title, Button } from "@mantine/core";
import { useQuery } from "@tanstack/react-query";

const patientListQuery = () => ({
  queryKey: ["patients", "list", "all"],
  queryFn: async () => {
    const session = await Auth.currentSession();
    const jwt = session.getIdToken().getJwtToken();
    const response = await fetch(
      `${process.env.REACT_APP_BASE_API_URL}/patients`,
      {
        headers: { Authorization: jwt, accept: "application/json" },
        credentials: "include",
        mode: "cors",
        method: "GET",
      }
    );

    if (!response.ok) {
      throw new Error("Network response not ok");
    }

    return response.json();
  },
});

export const loader = (queryClient) => async () => {
  const query = patientListQuery();
  // return data or fetch it
  return (
    queryClient.getQueryData(query.queryKey) ??
    (await queryClient.fetchQuery(query))
  );
};

export default function Patients() {
  const query = useQuery(patientListQuery());

  return (
    <div className="flex flex-col h-full">
      <div className="flex flex-row justify-between">
        <Title order={4} mb="lg">
          All Patients
        </Title>
        <Button component={Link} to="/add-patient">
          Add Patient
        </Button>
      </div>

      <Table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Date of Birth</th>
            <th>Sex</th>
            <th className="w-16">
              <span className="sr-only">View</span>
            </th>
          </tr>
        </thead>
        <tbody>
          {query.data?.map((patient) => (
            <tr key={patient.patient_id}>
              <td>{`${patient.first_name} ${patient.last_name}`}</td>
              <td>{patient.date_of_birth}</td>
              <td>{patient.sex === "M" ? "Male" : "Female"}</td>
              <td>
                <Button component={Link} to={`/patient/${patient.patient_id}`}>
                  View
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
}
