export const noteGen_v1 = [
  {
    type: "section_header",
    text: "Anxiety Disorders",
    id: "anx",
  },
  {
    type: "disorder",
    text: "Generalized Anxiety Disorder (GAD)",
    id: "gad",
    questions: [
      {
        type: "multiple_select",
        text: "A - Excessive anxiety and worry about a number of activities, 6+ months",
        options: [
          {
            id: "gad_a_occupational",
            text: "Excessive worry about work or school performance",
          },
          {
            id: "gad_a_random_loss",
            text: "Fear of random loss (family hurt, illness)",
          },
        ],
      },
      {
        type: "single_select",
        text: "Duration",
        id: "gad_duration",
        options: [
          {
            text: "Less than 1 month",
            value: "lt1mo",
          },
          {
            text: "1 to 5 months",
            value: "1-5mo",
          },
          {
            text: "6 months to 2 years",
            value: "6mo-2yr",
          },
          {
            text: "Longer than 2 years",
            value: "gt2yr",
          },
        ],
      },
      {
        type: "single_select",
        text: "Frequency - Worry is present...",
        id: "gad_frequency",
        options: [
          {
            text: "Occasionally",
          },
          {
            text: "About half of the days",
          },
          {
            text: "Most days",
          },
          {
            text: "Almost every day",
          },
          {
            text: "Every day",
          },
        ],
      },
      {
        type: "single_select",
        text: "Worry is...",
        id: "gad_difficult_to_control",
        options: [
          {
            text: "Difficult to control",
            value: true,
          },
          {
            text: "Not difficult to control",
            value: false,
          },
        ],
      },
      {
        type: "multiple_select",
        text: "3+ of the following symptoms required",
        options: [
          {
            text: "Restlessness or feeling keyed up or on edge",
            id: "gad_c1",
          },
          {
            text: "Being easily fatigued",
            id: "gad_c2",
          },
          {
            text: "Difficulty concentrating or mind going blank",
            id: "gad_c3",
          },
        ],
      },
      {
        type: "single_select",
        text: "Functional impairment",
        id: "gad_d",
        options: [
          {
            text: "Minimal",
          },
          {
            text: "Mild",
          },
          {
            text: "Moderate",
          },
          {
            text: "Severe",
          },
        ],
      },
    ],
  },
  {
    type: "disorder",
    text: "Panic Attacks",
    id: "pan",
    questions: [
      {
        type: "yes_no",
        text: '"Panic attacks" reported',
        id: "pan_reported",
      },
      {
        type: "multiple_select",
        text: "4+ of the following symptoms required:",
        options: [
          {
            id: "pan_a1",
            text: "Palpitations, pounding heart, or accelerated heart rate",
          },
          {
            id: "pan_a2",
            text: "Sweating",
          },
          {
            id: "pan_a3",
            text: "Trembling or shaking",
          },
          {
            id: "pan_a4",
            text: "Sensations fo shortness of breath or smothering",
          },
          {
            id: "pan_a5",
            text: "Feelings of choking",
          },
          {
            id: "pan_a6",
            text: "Chest pain or discomfort",
          },
          {
            id: "pan_a7",
            text: "Nausea or abdominal distress",
          },
          {
            id: "pan_a8",
            text: "Feeling dizzy, unsteady, light-headed or faint",
          },
          {
            id: "pan_a9",
            text: "Chills or heat sensations",
          },
          {
            id: "pan_a10",
            text: "Paresthesias (numbness or tingling sensations)",
          },
          {
            id: "pan_a11",
            text: "Derealization or depersonalization",
          },
          {
            id: "pan_a12",
            text: 'Fear of losing control or "going crazy"',
          },
          {
            id: "pan_a13",
            text: "Fear of dying",
          },
        ],
      },
      {
        type: "multiple_select",
        text: "At least one month of one or both of the following:",
        options: [
          {
            id: "pan_b1",
            text: "Persistent concern or worry about panic attacks or their consequences",
          },
          {
            id: "pan_b2",
            text: "A significant maladaptive change in behavior related to the attacks",
          },
        ],
      },
      {
        type: "single_select",
        text: "Duration",
        id: "pan_duration",
        options: [
          {
            text: "Less than 1 month",
            value: "lt1mo",
          },
          {
            text: "1 - 5 months",
            value: "1-5mo",
          },
          {
            text: "6 months - 2 years",
            value: "6mo-2yr",
          },
          {
            text: "Longer than 2 years",
            value: "gt2yr",
          },
        ],
      },
    ],
  },
  {
    type: "disorder",
    text: "Agoraphobia",
    id: "agr",
    questions: [
      {
        type: "multiple_select",
        text: "Marked fear or anxiety about 2+ of the following situations:",
        options: [
          {
            text: "Using public transportation",
            id: "agr_a1",
          },
          {
            text: "Being in open spaces",
            id: "agr_a2",
          },
          {
            text: "Being in enclosed spaces",
            id: "agr_a3",
          },
          {
            text: "Standing in line or being in a crowd",
            id: "agr_a4",
          },
          {
            text: "Being outside of the home alone",
            id: "agr_a5",
          },
        ],
      },
      {
        type: "yes_no",
        text: "Avoids these situations because escape might be difficult or help might not be available in the case of panic or embarassing symptoms",
        id: "agr_b",
      },
      {
        type: "single_select",
        text: "Agoraphobic situations provoke anxiety...",
        id: "agr_c",
        options: [
          {
            text: "Rarely",
          },
          {
            text: "About half of the time",
          },
          {
            text: "Most of the time",
          },
          {
            text: "Almost always",
          },
        ],
      },
      {
        type: "multiple_select",
        text: "D. Agoraphobic situations are actively avoided, require a companion, or are endured with intense fear and anxiety",
        options: [
          {
            text: "Actively avoided",
            value: "agr_d1",
          },
          {
            text: "Require a companion if possible",
            value: "agr_d2",
          },
          {
            text: "If cannot avoid or bring a companion, endure with intense fear and anxiety",
            value: "agr_d3",
          },
        ],
      },
      {
        type: "yes_no",
        text: "E. The fear or anxiety is out of proportion to the actual danger posed by the agoraphobic situations and to the sociocultural context",
        id: "agr_e",
      },
      {
        type: "single_select",
        text: "F. Fear, anxiety, or avoidance is persistent (6+ months)",
        id: "agr_f",
        options: [
          {
            text: "Less than 1 month",
            value: "lt1mo",
          },
          {
            text: "1 - 5 months",
            value: "1-5mo",
          },
          {
            text: "6 months - 2 years",
            value: "6mo-2yr",
          },
          {
            text: "Longer than 2 years",
            value: "gt2yr",
          },
        ],
      },
      {
        type: "single_select",
        text: "Functional impairment",
        id: "agr_g",
        options: [
          {
            text: "Minimal",
          },
          {
            text: "Mild",
          },
          {
            text: "Moderate",
          },
          {
            text: "Severe",
          },
        ],
      },
    ],
  },
  {
    type: "disorder",
    text: "Social Anxiety Disorder",
    id: "soc",
    questions: [
      {
        type: "yes_no",
        text: "A. Marked fear or anxiety about one or more social situations in which the individual is exposed to possible scrutiny by others",
        id: "soc_a_reported",
      },
      {
        type: "multiple_select",
        text: "Situations that trigger fear or anxiety:",
        options: [
          {
            text: "Public speaking",
            id: "soc_a_sit_1",
          },
          {
            text: "Giving a public performance (music, theater, etc.)",
            id: "soc_a_sit_2",
          },
          {
            text: "Interacting with unfamiliar people or strangers",
            id: "soc_a_sit_3",
          },
          {
            text: "Attending parties or social gatherings",
            id: "soc_a_sit_4",
          },
          {
            text: "Starting conversations",
            id: "soc_a_sit_5",
          },
          {
            text: "Making eye contact",
            id: "soc_a_sit_6",
          },
          {
            text: "Dating",
            id: "soc_a_sit_7",
          },
          {
            text: "Entering a room in which people are already seated",
            id: "soc_a_sit_8",
          },
          {
            text: "Returning items to a store",
            id: "soc_a_sit_9",
          },
          {
            text: "Eating in front of others",
            id: "soc_a_sit_10",
          },

          {
            text: "Using a public restroom",
            id: "soc_a_sit_11",
          },
        ],
      },
      {
        type: "multiple_select",
        text: "Anxiety symptoms experienced in the selected situations:",
        options: [
          {
            text: "Blushing",
            id: "soc_a_sym_1",
          },
          {
            text: "Fast heartbeat",
            id: "soc_a_sym_2",
          },
          {
            text: "Trembling",
            id: "soc_a_sym_3",
          },
          {
            text: "Sweating",
            id: "soc_a_sym_4",
          },
          {
            text: "Upset stomach or nausea",
            id: "soc_a_sym_5",
          },
          {
            text: "Trouble catching your breath",
            id: "soc_a_sym_6",
          },
          {
            text: "Dizziness or lightheadedness",
            id: "soc_a_sym_7",
          },
          {
            text: "Feeling that your mind has gone blank",
            id: "soc_a_sym_8",
          },
          {
            text: "Muscle tension",
            id: "soc_a_sym_9",
          },
          {
            text: "Shaky voice",
            id: "soc_a_sym_10",
          },
        ],
      },
      {
        type: "yes_no",
        text: "B. Individual fears being negatively evaluated or rejected",
        id: "soc_b",
      },
      {
        type: "single_select",
        text: "C. The social situations almost always provoke fear and anxiety",
        id: "soc_c",
        options: [
          {
            text: "Rarely",
          },
          {
            text: "About half of the time",
          },
          {
            text: "Most of the time",
          },
          {
            text: "Almost always",
          },
        ],
      },
      {
        type: "multiple_select",
        text: "D. The social situations are avoided, or endured with intense fear and anxiety",
        options: [
          {
            text: "Avoided if possible",
            id: "soc_d1",
          },
          {
            text: "If cannot avoid, endured with anxiety",
            id: "soc_d2",
          },
        ],
      },
      {
        type: "yes_no",
        text: "E. The fear or anxiety is out of proportion to the actual threat posed by the social situation and to the sociocultural context.",
        id: "soc_e",
      },
      {
        type: "single_select",
        text: "F. The fear, anxiety, or avoidance is persistent, typically lasting for 6 months or more.",
        id: "soc_f",
        options: [
          {
            text: "Less than 1 month",
            value: "lt1mo",
          },
          {
            text: "1 - 5 months",
            value: "1-5mo",
          },
          {
            text: "6 months - 2 years",
            value: "6mo-2yr",
          },
          {
            text: "Longer than 2 years",
            value: "gt2yr",
          },
        ],
      },
    ],
  },
  {
    type: "disorder",
    text: "Specific Phobia",
    id: "phb",
    questions: [
      {
        type: "multiple_select",
        text: "A. Marked fear or anxiety about a specific object or situation",
        options: [
          {
            text: "Animal",
            id: "phb_animal",
          },
          {
            text: "Situational",
            id: "phb_situational",
          },
          {
            text: "Blood",
            id: "phb_blood",
          },
          {
            text: "Injections or transfusions",
            id: "phb_injection",
          },
          {
            text: "Injury",
            id: "phb_injury",
          },
          {
            text: "Other medical care",
            id: "phb_other_medical",
          },
          {
            text: "Natural environment",
            id: "phb_natural_environment",
          },
          {
            text: "phb_other",
            id: "phb_other",
          },
        ],
      },
      {
        type: "yes_no",
        text: "B. The phobic object or situation almost always provokes immediate fear or anxiety.",
        id: "phb_b",
      },
      {
        type: "yes_no",
        text: "C. The phobic object or situation is actively avoided or endured with intense fear or anxiety.",
        id: "phb_c",
      },
      {
        type: "yes_no",
        text: "D. The fear or anxiety is out of proportion to the actual danger posed by the specific object or situation and to the sociocultural context.",
        id: "phb_d",
      },
      {
        type: "single_select",
        text: "E. The fear, anxiety, or avoidance is persistent, typically lasting for 6 months or more.",
        id: "phb_e",
        options: [
          {
            text: "Less than 1 month",
            value: "lt1mo",
          },
          {
            text: "1 - 5 months",
            value: "1-5mo",
          },
          {
            text: "6 months - 2 years",
            value: "6mo-2yr",
          },
          {
            text: "Longer than 2 years",
            value: "gt2yr",
          },
        ],
      },
      {
        type: "single_select",
        text: "Functional impairment",
        id: "phb_f",
        options: [
          {
            text: "Minimal",
          },
          {
            text: "Mild",
          },
          {
            text: "Moderate",
          },
          {
            text: "Severe",
          },
        ],
      },
    ],
  },
  {
    type: "disorder",
    text: "Separation Anxiety Disorder",
    id: "sep",
    questions: [
      {
        type: "multiple_select",
        text: "A. Developmentally inappropriate and excessive fear or anxiety concerning separation from those to whom the individual is attached, 3+ of the following",
        options: [
          {
            text: "1. Recurrent excessive distress when anticipating or experiencing separation from home or from major attachment figures.",
            id: "sep_a1",
          },
          {
            text: "2. Persistent and excessive worry about losing major attachment figures or about pos­sible harm to them, such as illness, injury, disasters, or death.",
            id: "sep_a2",
          },
          {
            text: "3. Persistent and excessive worry about experiencing an untoward event (e.g., getting lost, being kidnapped, having an accident, becoming ill) that causes separation from a major attachment figure.",
            id: "sep_a3",
          },
          {
            text: "4. Persistent reluctance or refusal to go out, away from home, to school, to work, or elsewhere because of fear of separation.",
            id: "sep_a4",
          },
          {
            text: "5. Persistent and excessive fear of or reluctance about being alone or without major attachment figures at home or in other settings.",
            id: "sep_a5",
          },
          {
            text: "6. Persistent reluctance or refusal to sleep away from home or to go to sleep without being near a major attachment figure.",
            id: "sep_a6",
          },
          {
            text: "7. Repeated nightmares involving the theme of separation.",
            id: "sep_a7",
          },
          {
            text: "8. Repeated complaints of physical symptoms (e.g., headaches, stomachaches, nau­sea, vomiting) when separation from major attachment figures occurs or is antici­pated.",
            id: "sep_a8",
          },
        ],
      },
      {
        type: "single_select",
        text: "B. The fear, anxiety, or avoidance is persistent (6+ months in adults)",
        id: "sep_duration",
        options: [
          {
            text: "Less than 1 month",
            value: "lt1mo",
          },
          {
            text: "1 - 5 months",
            value: "1-5mo",
          },
          {
            text: "6 months - 2 years",
            value: "6mo-2yr",
          },
          {
            text: "Longer than 2 years",
            value: "gt2yr",
          },
        ],
      },
      {
        type: "single_select",
        text: "Functional impairment",
        id: "sep_c",
        options: [
          {
            text: "Minimal",
          },
          {
            text: "Mild",
          },
          {
            text: "Moderate",
          },
          {
            text: "Severe",
          },
        ],
      },
    ],
  },
  {
    type: "section_header",
    text: "Depressive Disorders",
    id: "dep",
  },
  {
    type: "disorder",
    text: "Major Depressive Episode",
    id: "mdd",
    questions: [
      {
        type: "multiple_select",
        text: "A. 5+ of the following symptoms required",
        options: [
          {
            text: "Depressed mood",
            id: "mdd_a1",
          },
          {
            text: "Diminished interest or pleasure in activities",
            id: "mdd_a2",
          },
          {
            text: "Significant weight loss or gain, or decrease or increase in appetite",
            id: "mdd_a3",
          },
          {
            text: "Insomnia or hypersomnia",
            id: "mdd_a4",
          },
          {
            text: "Psychomotor agitation or retardation observed by others",
            id: "mdd_a5",
          },
          {
            text: "Fatigue or loss of energy",
            id: "mdd_a6",
          },
          {
            text: "Feelings of worthlessness, or excessive or inappropriate guilt",
            id: "mdd_a7",
          },
          {
            text: "Diminished ability to think or concentrate, or indecisiveness",
            id: "mdd_a8",
          },
        ],
      },
      {
        type: "multiple_select",
        text: "Suicidality",
        options: [
          {
            text: "Recurrent thoughts of death",
            id: "mdd_a9_thoughts_of_death",
          },
          {
            text: "Suicidal ideation",
            id: "mdd_a9_suicidal_ideation",
          },
          {
            text: "Suicide plan",
            id: "mdd_a9_suicide_plan",
          },
          {
            text: "Suicide attempt",
            id: "mdd_a9_suicide_attempt",
          },
        ],
      },
      {
        type: "single_select",
        text: "Suicide attempt count",
        id: "mdd_a9_suicide_attempt_count",
        conditions: (symptoms) => !!symptoms.mdd_a9_suicide_attempt,
        options: [
          {
            text: "0",
          },
          {
            text: "1",
          },
          {
            text: "2",
          },
          {
            text: "3",
          },
          {
            text: "4",
          },
          {
            text: "5+",
          },
        ],
      },
      {
        type: "single_select",
        text: "Functional impairment",
        id: "mdd_severity",
        options: [
          {
            text: "Minimal",
          },
          {
            text: "Mild",
          },
          {
            text: "Moderate",
          },
          {
            text: "Severe",
          },
        ],
      },
      {
        type: "single_select",
        text: "Duration",
        id: "mdd_duration",
        options: [
          {
            text: "Less than 2 weeks",
            value: "lt2wk",
          },
          {
            text: "2 - 4 weeks",
            value: "2-4wk",
          },
          {
            text: "1 - 2 months",
            value: "1-2mo",
          },
          {
            text: "3 - 5 months",
            value: "3-5mo",
          },
          {
            text: "6 - 11 months",
            value: "6-11mo",
          },
          {
            text: "Between 1 and 2 years",
            value: "1-2yr",
          },
          {
            text: "Longer than 2 years",
            value: "gt2yr",
          },
        ],
      },
      {
        type: "single_select",
        text: "Frequency",
        id: "mdd_frequency",
        options: [
          {
            text: "Occasionally",
          },
          {
            text: "About half of the days",
          },
          {
            text: "Most days",
          },
          {
            text: "Almost every day",
          },
          {
            text: "Every day",
          },
        ],
      },
      {
        type: "single_select",
        text: "Number of episodes",
        id: "mdd_episode_count",
        options: [
          {
            text: "1",
          },
          {
            text: "2",
          },
          {
            text: "3",
          },
          {
            text: "4",
          },
          {
            text: "5 or more",
          },
        ],
      },
      {
        type: "yes_no",
        text: "Started after a loss?",
        id: "mdd_response_to_loss",
      },
      {
        type: "yes_no",
        text: "Tied to thoughts of the loss",
        id: "mdd_tied_to_loss",
      },
      {
        type: "yes_no",
        text: "Persistent, not completely tied to thoughts of the loss",
        id: "mdd_not_tied_to_loss",
      },
    ],
  },
  {
    type: "section_header",
    text: "Bipolar and Related Disorders",
    id: "bip",
  },
  {
    type: "disorder",
    text: "Manic/Hypmanic Episode",
    id: "man",
    questions: [
      {
        type: "multiple_select",
        text: "A distinct period of abnormally and persistently elevated, expansive, or irritable mood and abnormally and persistently increased goal-directed activity or energy",
        options: [
          {
            text: "Elevated or expansive mood",
            id: "man_elevated_mood",
          },
          {
            text: "Irritable mood",
            id: "man_irritable_mood",
          },
        ],
      },
      {
        type: "single_select",
        text: "Duration",
        id: "man_duration",
        options: [
          {
            text: "One day or less",
            value: "lte1d",
          },
          {
            text: "2 - 3 days",
            value: "2-3d",
          },
          {
            text: "4 - 6 days",
            value: "4-6d",
          },
          {
            text: "1 week or longer",
            value: "gte1wk",
          },
        ],
      },
      {
        type: "multiple_select",
        text: "Associated symptoms:",
        options: [
          {
            text: "Inflated self-esteem or grandiosity",
            id: "man_b1",
          },
          {
            text: "Decreased need for sleep",
            id: "man_b2",
          },
          {
            text: "More talkative than usual or pressure to keep talking",
            id: "man_b3",
          },
          {
            text: "Flight of ideas or subjective experience that thoughts are racing",
            id: "man_b4",
          },
          {
            text: "Distractibility (i.e., attention too easily drawn to unimportant or irrelevant external stimuli), as reported or observed",
            id: "man_b5",
          },
          {
            text: "Increase in goal-directed activity (either socially, at work or school, or sexually) or psychomotor agitation (i.e., purposeless non-goal-directed activity)",
            id: "man_b6",
          },
          {
            text: "Excessive involvement in activities that have a high potential for painful conse­quences (e.g., engaging in unrestrained buying sprees, sexual indiscretions, or foolish business investments)",
            id: "man_b7",
          },
        ],
      },
      {
        type: "single_select",
        text: "Functional impairment",
        id: "man_severity",
        options: [
          {
            text: "Minimal",
          },
          {
            text: "Mild",
          },
          {
            text: "Moderate",
          },
          {
            text: "Severe",
          },
        ],
      },
      {
        type: "yes_no",
        text: "Hospitalization necessary?",
        id: "man_hospitalized",
      },
      {
        type: "yes_no",
        text: "Change in behavior clearly different from usual behavior",
        id: "man_clear_difference_in_behavior",
      },
      {
        type: "yes_no",
        text: "Change in behavior clearly noticed by others",
        id: "man_clearly_noticed_by_others",
      },
      {
        type: "single_select",
        text: "Number of episodes",
        id: "man_episode_count",
        options: [
          {
            text: "1",
          },
          {
            text: "2",
          },
          {
            text: "3",
          },
          {
            text: "4",
          },
          {
            text: "5 or more",
          },
        ],
      },
    ],
  },
  {
    type: "section_header",
    text: "Schizophrenia and Related Disorders",
    id: "sch-h",
  },
  {
    type: "disorder",
    text: "Schizophrenia spectrum",
    id: "sch",
    questions: [
      {
        type: "multiple_select",
        text: "Delusions",
        options: [
          {
            text: "Persecutory delusions",
            id: "delusions_persecutory",
          },
          {
            text: "Referential delusions",
            id: "delusions_referential",
          },
          {
            text: "Erotomanic delusions",
            id: "delusions_erotomanic",
          },
          {
            text: "Thought insertion/ withdrawal",
            id: "delusions_thought_withdrawal_insertion",
          },
          {
            text: "Delusions of control",
            id: "delusions_of_control",
          },
        ],
      },
      {
        type: "multiple_select",
        text: "Hallucinations",
        options: [
          {
            text: "Visual",
            id: "hallucinations_visual",
          },
          {
            text: "Auditory",
            id: "hallucinations_auditory",
          },
          {
            text: "Tactile",
            id: "hallucinations_tactile",
          },
        ],
      },
      {
        type: "multiple_select",
        text: "Disorganized behaviors",
        options: [
          {
            text: "Disorganized thinking",
            id: "disorganized_thinking",
          },
          {
            text: "Disorganized speech (incoherence)",
            id: "incoherence",
          },
        ],
      },
      {
        type: "multiple_select",
        text: "Catatonic behavior",
        options: [
          {
            text: "Mutism and stupor",
            id: "mutism_stupor",
          },
          {
            text: "Staring",
            id: "staring",
          },
          {
            text: "Grimacing",
            id: "grimace",
          },
          {
            text: "Echoing of speech",
            id: "echoing_speech",
          },
        ],
      },
      {
        type: "multiple_select",
        text: "Negative symptoms",
        options: [
          {
            text: "Diminished emotional expression",
            id: "diminished_emotional_expression",
          },
          {
            text: "Avolition",
            id: "avolition",
          },
          {
            text: "Alogia",
            id: "alogia",
          },
          {
            text: "Anhedonia",
            id: "anhedonia",
          },
          {
            text: "Asociality",
            id: "asociality",
          },
        ],
      },
    ],
  },
  {
    type: "section_header",
    text: "Neurodevelopmental disorders",
    id: "neu",
  },
  {
    type: "disorder",
    text: "ADHD",
    id: "adhd",
    questions: [
      {
        type: "multiple_select",
        text: "1. Inattention: 6+ of the following symptoms have persisted for at least 6 months to a degree that is inconsistent with developmental level and that negatively impacts directly on social and academic/occupational activities:",
        options: [
          {
            text: "a. Often fails to give close attention to details or makes careless mistakes in schoolwork, at work, or during other activities",
            id: "adhd_1a",
          },
          {
            text: "b. Often has difficulty sustaining attention in tasks or play activities",
            id: "adhd_1b",
          },
          {
            text: "c. Often does not seem to listen when spoken to directly",
            id: "adhd_1c",
          },
          {
            text: "d. Often does not follow through on instructions and fails to finish schoolwork, chores, or duties in the workplace",
            id: "adhd_1d",
          },
          {
            text: "e. Often has difficulty organizing tasks and activities",
            id: "adhd_1e",
          },
          {
            text: "f. Often avoids, dislikes, or is reluctant to engage in tasks that require sustained mental effort",
            id: "adhd_1f",
          },
          {
            text: "g. Often loses things necessary for tasks or activities",
            id: "adhd_1g",
          },
          {
            text: "h. Is often easily distracted by extraneous stimuli",
            id: "adhd_1h",
          },
          {
            text: "i. Is often forgetful in daily activities",
            id: "adhd_1i",
          },
        ],
      },
      {
        type: "multiple_select",
        text: "2. Hyperactivity and impulsivity: 6+ (5+ for age 17+) of the following symptoms have persisted for at least 6 months to a degree that is inconsistent with developmental level and that negatively impacts directly on social and academic/occupational activities:",
        options: [
          {
            text: "a. Often fidgets with or taps hands or feet or squirms in seat",
            id: "adhd_2a",
          },
          {
            text: "b. Often leaves seat in situations when remaining seated is expected",
            id: "adhd_2b",
          },
          {
            text: "c. Often runs about or climbs in situations where it is inappropriate",
            id: "adhd_2c",
          },
          {
            text: "d. Often unable to play or engage in leisure activities quietly",
            id: "adhd_2d",
          },
          {
            text: 'e. Is often "on the go," acting as if "driven by a motor"',
            id: "adhd_2e",
          },
          {
            text: "f. Often talks excessively",
            id: "adhd_2f",
          },
          {
            text: "g. Often blurts out an answer before a question has been completed",
            id: "adhd_2g",
          },
          {
            text: "h. Often has difficulty waiting his or her turn",
            id: "adhd_2h",
          },
          {
            text: "i. Often interrupts or intrudes on others",
            id: "adhd_2i",
          },
        ],
      },
      {
        type: "single_select",
        text: "Age at first symptoms (several symptoms must be present prior to age 12",
        id: "adhd_age_at_first_symptoms",
        options: [
          {
            text: "Younger than 3",
            value: "lt3",
          },
          {
            text: "3 - 5 years",
            value: "3-5yr",
          },
          {
            text: "6 - 8 years",
            value: "6-8yr",
          },
          {
            text: "9 - 11 years",
            value: "9-11yr",
          },
          {
            text: "12 - 16 years",
            value: "12-16yr",
          },
          {
            text: "17 - 20 years",
            value: "17-20yr",
          },
          {
            text: "21 - 25 years",
            value: "21-25yr",
          },
          {
            text: "Older than 25",
            value: "gt25yr",
          },
        ],
      },
      {
        type: "multiple_select",
        text: "Several symptoms present in each of the following settings:",
        options: [
          {
            text: "Home",
            value: "adhd_setting_home",
          },
          {
            text: "School",
            value: "adhd_setting_school",
          },
          {
            text: "Work",
            value: "adhd_setting_work",
          },
          {
            text: "With friends",
            value: "adhd_setting_with_friends",
          },
          {
            text: "Other activites",
            value: "adhd_setting_other_activities",
          },
        ],
      },
      {
        type: "single_select",
        text: "Impact on occupational functioning",
        id: "adhd_impact_occupational",
        options: [
          {
            text: "Minimal",
          },
          {
            text: "Mild",
          },
          {
            text: "Moderate",
          },
          {
            text: "Severe",
          },
        ],
      },
      {
        type: "single_select",
        text: "Impact on relationships",
        id: "adhd_impact_occupational",
        options: [
          {
            text: "Minimal",
          },
          {
            text: "Mild",
          },
          {
            text: "Moderate",
          },
          {
            text: "Severe",
          },
        ],
      },
    ],
  },
];
