import { Text, Button } from "@mantine/core";

/*
params is in this format:
{
  id: "gad_duration",
  text: "Duration",
  options: [
    {
      text: "Less than 1 month",
      value: "lt_1mo"
    },
    {
      text: "1 to 5 months",
      value: "1mo_5mo"
    }, ...
  ]
}
*/
const SingleSelectQuestion = ({ symptoms, setSymptoms, params }) => {
  const { id: symptomId, text, options, conditions } = params;

  if (conditions !== undefined && !conditions(symptoms)) {
    return null;
  }

  return (
    <div className="my-2">
      <Text className="my-1">{text}</Text>
      <div className="flex flex-row flex-wrap gap-2">
        {options.map(({ text, value }) => {
          // Fall back to text if values are not provided in the options list
          if (value === undefined) {
            value = text;
          }
          return (
            <Button
              variant={symptoms[symptomId] === value ? "filled" : "outline"}
              onClick={() => {
                const newSymptoms = { ...symptoms };
                newSymptoms[symptomId] = value;
                setSymptoms(newSymptoms);
              }}
              key={text}
              classNames={{
                root: "py-2 min-h-[36px] h-fit leading-snug",
                label: "whitespace-normal",
              }}
            >
              {text}
            </Button>
          );
        })}
      </div>
    </div>
  );
};

/*
params is in this format:
{
  text: "3+ of the following symptoms required",
  options: [
    {
      id: "gad_c1",
      text: "Restlessness or feeling keyed up or on edge",
    },
    {
      id: "gad_c2",
      text: "Being easily fatigued",
    }, ...
  ]
}
*/
const MultipleSelectQuestion = ({ symptoms, setSymptoms, params }) => {
  const { text, options, conditions } = params;

  if (conditions !== undefined && !conditions(symptoms)) {
    return null;
  }

  return (
    <div className="my-2">
      <Text className="my-1">{text}</Text>
      <div className="flex flex-row flex-wrap gap-2">
        {options.map(({ id: symptomId, text }) => (
          <Button
            variant={symptoms[symptomId] === true ? "filled" : "outline"}
            onClick={() => {
              const newSymptoms = { ...symptoms };
              newSymptoms[symptomId] = !symptoms[symptomId];
              setSymptoms(newSymptoms);
            }}
            key={symptomId}
            classNames={{
              root: "py-2 min-h-[36px] h-fit leading-snug",
              label: "whitespace-normal",
            }}
          >
            {text}
          </Button>
        ))}
      </div>
    </div>
  );
};

// Just a single select question with default options
const YesNoQuestion = ({ symptoms, setSymptoms, params }) => {
  params["options"] = [
    {
      text: "Yes",
      value: true,
    },
    {
      text: "No",
      value: false,
    },
  ];
  return SingleSelectQuestion({ symptoms, setSymptoms, params });
};

const DefaultQuestion = ({ symptoms, setSymptoms, params }) => {
  return (
    <div className="text-xl text-red-600 font-extrabold">
      This is the default question element. Error
    </div>
  );
};

export {
  SingleSelectQuestion,
  MultipleSelectQuestion,
  YesNoQuestion,
  DefaultQuestion,
};
