export const initialData_v1 = {
  gad_a_occupational: null,
  gad_a_random_loss: null,
  gad_duration: "",
  gad_moredaysthannot: null,
  gad_difficult_to_control: null,
  gad_b: null,
  gad_c1: null,
  gad_c2: null,
  gad_c3: null,
  gad_c4: null,
  gad_c5: null,
  gad_c6: null,
  gad_d: null,
  pan_reported: null,
  pan_a1: null,
  pan_a2: null,
  pan_a3: null,
  pan_a4: null,
  pan_a5: null,
  pan_a6: null,
  pan_a7: null,
  pan_a8: null,
  pan_a9: null,
  pan_a10: null,
  pan_a11: null,
  pan_a12: null,
  pan_a13: null,
  pan_b1: null,
  pan_duration: "",
  agr_a1: null,
  agr_a2: null,
  agr_a3: null,
  agr_a4: null,
  agr_a5: null,
  agr_b: null,
  agr_c: "",
  agr_d1: null,
  agr_d2: null,
  agr_d3: null,
  agr_e: null,
  agr_f: "",
  agr_g: null,
  soc_a_reported: null,
  soc_a_sit_1: null,
  soc_a_sit_4: null,
  soc_a_sit_7: null,
  soc_a_sym_1: null,
  soc_a_sym_3: null,
  soc_a_sym_8: null,
  soc_b: null,
  soc_c: "",
  soc_d1: null,
  soc_d2: null,
  soc_e: null,
  soc_duration: "",
  phb_animal: null,
  phb_situational: null,
  phb_blood: null,
  phb_injection: null,
  phb_injury: null,
  phb_other_medical: null,
  phb_natural_environment: null,
  phb_other: null,
  phb_b: null,
  phb_c: null,
  phb_d: null,
  phb_duration: "",
  phb_f: null,
  sep_a1: null,
  sep_a2: null,
  sep_a3: null,
  sep_a4: null,
  sep_a5: null,
  sep_a6: null,
  sep_a7: null,
  sep_a8: null,
  sep_duration: "",
  sep_c: null,
  mdd_a1: null,
  mdd_a2: null,
  mdd_a3: null,
  mdd_a4: null,
  mdd_a5: null,
  mdd_a6: null,
  mdd_a7: null,
  mdd_a8: null,
  mdd_a9_thoughts_of_death: null,
  mdd_a9_suicidal_ideation: null,
  mdd_a9_suicide_plan: null,
  mdd_a9_suicide_attempt: null,
  mdd_a9_suicide_attempt_count: "",
  mdd_severity: "",
  mdd_duration: "",
  mdd_frequency: "",
  mdd_episode_count: "",
  mdd_response_to_loss: null,
  mdd_tied_to_loss: null,
  mdd_not_tied_to_loss: null,
  man_elevated_mood: null,
  man_irritable_mood: null,
  man_duration: "",
  man_b1: null,
  man_b2: null,
  sleep_hours_normal: "",
  sleep_hours_manic: "",
  still_rested: null,
  man_b3: null,
  man_b4: null,
  man_b5: null,
  man_b6: null,
  man_b7: null,
  man_severity: "",
  man_hospitalized: null,
  man_clear_difference_in_behavior: null,
  man_clearly_noticed_by_others: null,
  man_episode_count: "",
  delusions_persecutory: null,
  delusions_referential: null,
  delusions_erotomanic: null,
  delusions_thought_withdrawal_insertion: null,
  delusions_of_control: null,
  hallucinations_visual: null,
  hallucinations_auditory: null,
  hallucinations_tactile: null,
  disorganized_thinking: null,
  incoherence: null,
  mutism_stupor: null,
  staring: null,
  grimace: null,
  echoing_speech: null,
  diminished_emotional_expression: null,
  avolition: null,
  alogia: null,
  anhedonia: null,
  asociality: null,
  adhd_1a: null,
  adhd_1b: null,
  adhd_1c: null,
  adhd_1d: null,
  adhd_1e: null,
  adhd_1f: null,
  adhd_1g: null,
  adhd_1h: null,
  adhd_1i: null,
  adhd_2a: null,
  adhd_2b: null,
  adhd_2c: null,
  adhd_2d: null,
  adhd_2e: null,
  adhd_2f: null,
  adhd_2g: null,
  adhd_2h: null,
  adhd_2i: null,
  adhd_age_at_first_symptoms: "",
  adhd_setting_home: null,
  adhd_setting_school: null,
  adhd_setting_work: null,
  adhd_setting_with_friends: null,
  adhd_setting_with_family: null,
  adhd_setting_other_activities: null,
  adhd_impact_occupational: "",
  adhd_impact_relationships: "",
};
