import { Auth } from "aws-amplify";
import { useEffect, useState } from "react";
import { Text } from "@mantine/core";

const Dashboard = () => {
  const [token, setToken] = useState("");

  useEffect(() => {
    Auth.currentSession().then((res) => {
      const jwt = res.getIdToken().getJwtToken();
      setToken(jwt);
    });
  }, []);

  return (
    <>
      <div>This is the dashboard</div>
      <div>{`Id token: ${token}`}</div>
      <Text>Testing the Mantine Text element!</Text>
    </>
  );
};

export default Dashboard;
